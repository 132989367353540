#blur-back {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  min-width: 100%;
  z-index: 1000;
}

.listed li {
  margin-left: 1.5rem;
}

@media screen and (width > 768px) {
  .active {
    border-bottom: 2px solid #fff;
  }
  .storyAnimate {
    /* background: url("../../assets/secschpic1.jpeg"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    height: 100vh;
    transition: 5s;
    animation-name: animateBg;
    animation-duration: alternate-reverse;
    animation-duration: 30s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
  }
  @keyframes animateBg {
    0% {
      background-image: none;
    }
    25% {
      background-image: url("../../assets/secschpic2.webp");
    }
    50% {
      background-image: url("../../assets/secschpic3.jpeg");
    }
    75% {
      background-image: url("../../assets/secschpic4.jpeg");
    }
    90% {
      background-image: url("../../assets/secschpic5.jpg");
    }
    100% {
      background-image: url("../../assets/secschpic3.jpeg");
    }
  }
  .hamburger {
    display: none;
  }
  #blur-back {
    justify-content: space-around;
  }
  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
}
@media screen and (width < 768px) {
  .hamburger {
    display: block;
  }
  .navigation {
    display: none;
  }
  #blur-back {
    justify-content: space-between;
  }
  .storyAnimate {
    background: url("../../assets/secschpic1.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    height: 75vh;
    transition: 5s;
    animation-name: animateBg;
    animation-duration: alternate-reverse;
    animation-duration: 30s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
  }
  @keyframes animateBg {
    0% {
      background-image: url("../../assets/secschpic1.jpeg");
    }
    25% {
      background-image: url("../../assets/secschpic2.webp");
    }
    50% {
      background-image: url("../../assets/secschpic3.jpeg");
    }
    75% {
      background-image: url("../../assets/secschpic4.jpeg");
    }
    90% {
      background-image: url("../../assets/secschpic5.jpg");
    }
    100% {
      background-image: url("../../assets/secschpic3.jpeg");
    }
  }
  .active {
    border-bottom: 2px solid #fff;
  }
}
