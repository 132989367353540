@import url("https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700&display=swap");

.cardCenter {
  align-items: center;
  place-items: center;
  justify-self: center;
}
.cardContainer {
  align-items: center;
  place-items: center;
  justify-self: center;
  justify-content: center;
  row-gap: 3rem;
}

.cardIcons {
  padding-top: 1rem;
}

.polygon {
  clip-path: polygon(
    99.75% 0%,
    100% 100%,
    16.5% 100%,
    0% 90.25%,
    0% 0%,
    73.5% 0%
  );
}
.card-image-bg {
  background: url("https://www.propertypro.ng/blog/wp-content/uploads/2018/08/Top-20-Best-Secondary-Schools-In-Nigeria.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
a,
button,
h1,
p,
li,
input {
  font-family: "Khula", sans-serif;
}
.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  background-blend-mode: color;
}
.description {
  background-image: url("../../assets/secschpic4.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 90%;
}
