@tailwind base;
@tailwind components;
@tailwind utilities;

.p-portal {
  background: url("./assets/protalBG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.s-portal {
  background: url("./assets/devs.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slide-news-container {
  margin: 0 auto;
  background-color: red;
  /* overflow: hidden; */
}

.slide-news-item {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slide-news-item.active {
  opacity: 1;
}
